import React, { useState, useEffect } from 'react';
import './GameTable.css';

const GameTable = ({ games, fetchGames }) => {
    const [timers, setTimers] = useState([]);
    const [playedSounds, setPlayedSounds] = useState({}); // Track sounds played to prevent repeats

    const calculateRemainingTime = (startTime, levelTime, dealer, status) => {
        if (!dealer) return NaN;
        if (status !== 'started') return NaN;
        const now = new Date();
        const start = new Date(startTime);
        if (start > now) return NaN;

        const elapsed = Math.floor((now - start) / 1000);
        const remaining = levelTime * 60 - (elapsed % (levelTime * 60));
        return remaining > 0 ? remaining : 0;
    };

    const playSound = (levelSound) => {
        const audio = new Audio(levelSound);
        audio.play().catch((error) => console.error('Failed to play sound:', error));
    };

    useEffect(() => {
        if (games.length > 0) {
            const initialTimers = games.map(game =>
            calculateRemainingTime(game.start_time, game.level_time, game.dealer,game.gameStatus)
            );
            setTimers(initialTimers);
        }
    }, [games]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimers(prevTimers =>
                prevTimers.map((timer, index) => {
                    const game = games[index];

                    // Ensure the game is "started" and the level is within the first 5 seconds
                    const remainingTime = calculateRemainingTime(
                        game.start_time,
                        game.level_time,
                        game.dealer,
                        game.gameStatus
                    );

                    const timeSinceLevelStart = game.level_time * 60 - remainingTime;
                    if (
                        game.status === "started" &&
                        timeSinceLevelStart >= 0 &&
                        timeSinceLevelStart <= 60 &&
                        game.level_sound &&
                        !playedSounds[game.id] // Ensure sound plays only once per level
                    ) {
                        playSound(game.level_sound);
                        setPlayedSounds(prev => ({ ...prev, [game.id]: true }));
                    }

                    if (game.dealer && timer > 0) {
                        return remainingTime;
                    } else if (timer === 0) {
                        fetchGames(); // Refresh game data
                        setPlayedSounds(prev => ({ ...prev, [game.id]: false })); // Reset sound for new level
                        return remainingTime;
                    } else {
                        return NaN;
                    }
                })
            );
        }, 1000);

        return () => clearInterval(interval);
    }, [games, fetchGames, playedSounds]);

    const formatStartTime = (startTime) => {
        const date = new Date(startTime);
        const options = { hour: '2-digit', minute: '2-digit' };
        return date.toLocaleString('en-US', options);
    };

    const formatTime = (seconds) => {
        if (isNaN(seconds)) return "00:00";
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    return (
        <table className="game-table">
            <thead>
            <tr>
                <th>ID GAME</th>
                <th>Table</th>
                <th>Game Mode</th>
                <th>Start Time</th>
                <th>Current Level</th>
                <th>Small Blind</th>
                <th>Big Blind</th>
                <th>Ante</th>
                <th>Timer</th>
                <th>Dealer</th>
            </tr>
            </thead>
            <tbody>
            {games.map((game, index) => (
                <tr key={game.id}>
                    <td>{game.id}</td>
                    <td>{game.room_id}</td>
                    <td>{game.mode}</td>
                    <td>{formatStartTime(game.start_time)}</td>
                    <td>{game.level}</td>
                    <td className="blind">{game.small_blind}</td>
                    <td className="blind">{game.big_blind}</td>
                    <td>{game.ante}</td>
                    <td className="blind">{formatTime(timers[index])}</td>
                    <td>{game.dealer ? game.dealer.name : ''}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default GameTable;
